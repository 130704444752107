<template>
  <div ref="deviceDetailWrapper" class="page-wrapper pa-6">
    <div v-if="device">
      <div class="head">
        <div>
          <div class="d-flex align-center">
            <h2>{{ getDeviceName(device, currentCompanyId) }}</h2>
            <v-dialog v-model="qrCodeDialog" max-width="400">
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on" class="ml-2">
                  <v-icon>$qr</v-icon>
                </v-btn>
              </template>

              <v-card>
                <v-card-title class="qr-modal-headline flex-column align-start">
                  <h1>{{ getDeviceName(device, currentCompanyId) }}</h1>
                  <div class="span-bold" @click="copyController()">
                    <span>{{ $t('device.controller') }}: </span><span>{{ device.controller }}</span>
                    <v-icon x-small class="ml-1">mdi-content-copy</v-icon>
                  </div>
                </v-card-title>
                <v-card-text class="pb-0 px-15">
                  <vue-qrcode :value="device.controller" :width="400" :margin="4" />
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" @click="qrCodeDialog = false">
                    {{ $t('global.close') }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </div>
          <div class="device-info-wrapper">
            <div class="align-center">
              <div
                v-if="device.company.id !== currentCompanyId"
                class="company mr-4 d-flex align-center"
              >
                <v-icon small class="mr-1">$building</v-icon>
                <span>{{ device.company.name }}</span>
                <v-btn
                  class="ml-2"
                  v-if="!currentCompanyId && isDeviceAdmin"
                  @click="changeServiceCompanyModalOpen = true"
                  icon
                  small
                  ><v-icon small>mdi-pencil</v-icon></v-btn
                >
              </div>
              <div v-if="device.thingType?.thingTypeFamily?.name" class="span-bold mr-4">
                <span>{{ device.thingType?.thingTypeFamily?.name }}</span>
              </div>

              <div class="controller" @click="copyController()">
                <span class="span-bold">{{ device.controller }}</span>
                <v-icon x-small class="ml-1 mr-2">mdi-content-copy</v-icon>
              </div>
            </div>
            <div class="align-center">
              <div class="span-bold mr-2">
                <span>{{ this.$t(`device.operatingMode.${device.operatingMode}`) }}</span>
                <v-btn
                  v-if="permissionChecker([], eligibilities.CHANGE_OPERATING_MODE)"
                  @click="changeOperatingModeModalOpen = true"
                  icon
                  small
                  :disabled="isRunningServiceSequence()"
                  ><v-icon small>mdi-pencil</v-icon></v-btn
                >
              </div>
              <div class="operating-mode mr-2">
                <span>{{ this.$t('device.deviceListHeaders.inOperationSince') }}: </span
                ><span>{{ formatDate(device.inOperationSince) }}</span>
              </div>
            </div>
          </div>

          <div class="reports">
            <ReportBadge
              v-for="eventCode of getSortedErrors(device.eventDetectionPoints)"
              :key="`${eventCode.description}|${eventCode.severity}`"
              :text="eventCode.description"
              :severity="eventCode.severity"
              :clickable="true"
              :deviceId="device.id"
              :permissionChecker="permissionChecker"
            />
          </div>

          <div v-if="device.connectionStatus === 'OFFLINE'" class="mt-2">
            <span class="status-offline-badge">OFFLINE</span>
            <span class="last-status-change">
              {{ $t('device.lastSynchronization') }}:
              <b>{{ connectionStatusChangedTime }}</b>
            </span>
          </div>
        </div>
        <div class="head-buttons">
          <v-alert
            v-if="
              permissionChecker(
                permissions.CONFIGURATION_UPLOAD,
                eligibilities.DEVICE_CONFIGURATION,
              ) && deviceConfigurationMessage
            "
            dense
            :icon="false"
            :type="device.dataPropagationFailed ? 'warning' : 'info'"
            :color="device.dataPropagationFailed ? '' : '#5ec7f2'"
            class="mb-0"
            text
          >
            <div class="mb-1 text-center">{{ deviceConfigurationMessage }}</div>
            <v-btn
              v-if="device.needPropagateDeviceData"
              color="success"
              height="27"
              @click="propagateDeviceData()"
              :block="showBlockButtons"
              class="mb-1"
              depressed
              :disabled="isRunningServiceSequence()"
              :style="{ backgroundColor: isRunningServiceSequence() ? '#3E8F4250 !important' : '' }"
            >
              <v-icon class="mr-2">$upload</v-icon>
              {{ $t('device.propagate') }}
            </v-btn>
            <v-btn
              @click="confirmDiscardChangesModalOpen = true"
              color="error"
              :block="showBlockButtons"
              height="27"
              depressed
              :disabled="isRunningServiceSequence()"
              :style="{ backgroundColor: isRunningServiceSequence() ? '#8B313150 !important' : '' }"
              >{{ $t('global.discardChanges') }}</v-btn
            >
          </v-alert>
          <div class="buttons-row gap-4">
            <v-btn
              class="flex-1"
              v-if="
                permissionChecker(
                  permissions.CONFIGURATION_CHANGES,
                  eligibilities.DEVICE_CONFIGURATION,
                )
              "
              color="primary"
              @click="editDeviceModal = true"
              depressed
              :disabled="isRunningServiceSequence()"
            >
              <v-icon class="mr-2">$settings</v-icon>
              {{ $t('global.settings') }}
            </v-btn>
            <v-btn
              class="flex-1"
              depressed
              @click="openEligibilitiesView"
              v-if="
                currentCompanyId && currentCompanyPermissions[permissions.NEW_PRODUCT_CATALOGUE]
              "
              :disabled="isRunningServiceSequence()"
            >
              <v-icon class="mr-2">mdi-lock-outline</v-icon
              >{{ $t('global.productSettings') }}</v-btn
            >
          </div>
        </div>
      </div>
      <div>
        <h3 class="mt-6 mb-5">{{ $t('global.operatingValues') }}</h3>
        <div class="controls-wrapper">
          <component
            v-for="(operatingValue, index) in device.liveDeviceData.operatingValues"
            :key="'operatingValue_' + index"
            :is="chooseControlType(operatingValue.type)"
            :control="operatingValue"
            :deviceId="device.id"
            :isOffline="controlsInOfflineState"
            :connectionStatusChangedTime="device.connectionStatusChangedTime"
            :canEdit=" !isRunningServiceSequence() && (
              operatingValue.setEligibility && !currentCompanyId
                ? permissionChecker([], operatingValue.setEligibility)
                : permissionChecker(permissions.DEVICE_CONTROL, eligibilities.DEVICE_CONTROL))
            "
            @controlChanged="controlChanged()"
          ></component>
        </div>
      </div>
      <div>
        <div class="mt-6 mb-5 d-flex align-center flex-wrap">
          <h3 class="mr-5">{{ $t('global.controls') }}</h3>
          <!-- Disable expert mode for now -->
          <!-- <v-btn
            v-if="
              device.connectionStatus === 'OFFLINE' &&
              permissionChecker(permissions.EXPERT_MODE, eligibilities.EXPERT_MODE)
            "
            @click="confirmOpenExpertModeDialog = true"
            color="error"
            height="27"
          >
            {{ $t('device.enterExpertMode') }}
          </v-btn> -->
        </div>
        <div class="controls-wrapper">
          <component
            v-for="(control, index) in device.liveDeviceData.controls"
            :key="'control_' + index"
            :is="chooseControlType(control.type)"
            :control="control"
            :deviceId="device.id"
            :isOffline="controlsInOfflineState"
            :connectionStatusChangedTime="device.connectionStatusChangedTime"
            :canEdit=" !isRunningServiceSequence() && (
              control.setEligibility && !currentCompanyId
                ? permissionChecker([], control.setEligibility)
                : permissionChecker(permissions.DEVICE_CONTROL, eligibilities.DEVICE_CONTROL))
            "
            @controlChanged="controlChanged()"
          ></component>
        </div>
        <h3 class="mt-6 mb-5">{{ $t('global.tools') }}</h3>
        <div class="controls-wrapper">
          <component
            v-for="(serviceSequece, index) in device.liveDeviceData.serviceSequences"
            :key="'serviceSequence_' + index"
            :is="chooseControlType(serviceSequece.type)"
            :control="serviceSequece"
            :deviceId="device.id"
            :isOffline="controlsInOfflineState"
            :connectionStatusChangedTime="device.connectionStatusChangedTime"
            :canEdit=" !isRunningServiceSequence() && (
              serviceSequece.setEligibility && !currentCompanyId
                ? permissionChecker([], serviceSequece.setEligibility)
                : permissionChecker(permissions.DEVICE_CONTROL, eligibilities.DEVICE_CONTROL))
            "
            @controlChanged="controlChanged()"
            @openServiceSequenceWizard="openServiceSequenceWizard($event)"
          ></component>
          <v-overlay
            v-if="device.liveDeviceData.runningServiceSequence"
            absolute
            :opacity="0.5"
            :value="Boolean(device.liveDeviceData.runningServiceSequence)"
            style="border-radius: 3px"
          >
            <v-alert border="left" light colored-border prominent type="info" class="mb-0">
              <v-row align="center">
                <v-col class="grow">
                  {{ $t('device.serviceSequenceInProgress') }}
                </v-col>
              </v-row>
            </v-alert>
          </v-overlay>
        </div>
      </div>
      <v-tabs v-model="tab" show-arrows class="mt-10 d-none d-md-block">
        <v-tab
          v-for="(tabConfig, index) in accessibleTabs"
          :key="`tab-${index}`"
          :href="`#tab-${index}`"
        >
          {{ tabConfig.label }}
        </v-tab>
      </v-tabs>
      <!-- Replace tabs with a select dropdown -->
      <v-select
        v-model="selectedTab"
        :items="accessibleTabs"
        item-title="label"
        item-value="type"
        :label="$t('global.view')"
        density="comfortable"
        persistent-hint
        single-line
        return-object
        filled
        @input="onTabChange"
        class="mt-4 d-block d-md-none"
      >
        <template v-slot:selection="data">
          {{ data.item.label }}
        </template>
        <template v-slot:item="data">
          {{ data.item.label }}
        </template>
      </v-select>
      <v-tabs-items v-model="tab" touchless>
        <v-tab-item
          v-for="(tabConfig, index) in accessibleTabs"
          :key="`tab-${index}`"
          :value="'tab-' + index"
        >
          <component
            :is="selectTabType(tabConfig.type)"
            :tabConfig="tabConfig"
            :device="device"
            :permissionChecker="permissionChecker"
            :deviceDetailWrapper="$refs.deviceDetailWrapper"
            :isRunningServiceSequence="isRunningServiceSequence()"
            :active="tab === `tab-${index}`"
          ></component>
        </v-tab-item>
      </v-tabs-items>
      <!-- Make the tabs into a select -->
    </div>
    <div v-else-if="isLoading" class="d-flex justify-center mt-8">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </div>
    <div v-else class="pa-8">
      <v-alert prominent type="error">
        <v-row align="center">
          <v-col class="grow">
            {{ $t('device.deviceNotFound') }}
          </v-col>
          <v-col class="shrink">
            <v-btn @click="$router.back()">{{ $t('global.back') }}</v-btn>
          </v-col>
        </v-row>
      </v-alert>
    </div>

    <!-- Expert mode dialogs -->
    <template v-if="device">
      <v-dialog v-model="confirmOpenExpertModeDialog" max-width="500">
        <v-card>
          <v-card-title class="headline">{{
            $t('device.confirmOpenExpertModeModal.headline')
          }}</v-card-title>
          <v-card-text>{{ $t('device.confirmOpenExpertModeModal.text') }}</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="confirmOpenExpertModeDialog = false">{{ $t('global.cancel') }}</v-btn>
            <v-btn color="primary" @click="openExpertMode()">{{ $t('global.continue') }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="expertModeDialog"
        @click:outside="confirmCloseExpertModeDialog = true"
        persistent
        max-width="875"
      >
        <div>
          <div class="expert-mode-dialog--header">
            <span>{{ $t('device.expertModeModal.headline') }}</span>
            <v-btn @click="confirmCloseExpertModeDialog = true">{{
              $t('device.expertModeModal.exit')
            }}</v-btn>
          </div>
          <div class="expert-mode-dialog--body">
            <ToggleControlTile
              v-for="(control, index) in device.liveDeviceData.expertMode"
              :key="toForceRerenderCounter + '_expertMode_' + index"
              :control="control"
              :deviceId="device.id"
              @controlChanged="controlChanged()"
            />
          </div>
        </div>
      </v-dialog>
      <v-dialog v-model="confirmCloseExpertModeDialog" max-width="500">
        <v-card>
          <v-card-title class="headline">{{
            $t('device.confirmCloseExpertModeModal.headline')
          }}</v-card-title>
          <v-card-text>{{ $t('device.confirmCloseExpertModeModal.text') }}</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="confirmCloseExpertModeDialog = false">{{ $t('global.cancel') }}</v-btn>
            <v-btn color="primary" @click="closeExpertMode()">{{ $t('global.confirm') }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="deviceWentOfflineModal" max-width="500" persistent>
        <v-card>
          <v-card-title class="headline">{{
            $t('device.deviceWentOfflineModal.headline')
          }}</v-card-title>
          <v-card-text>{{ $t('device.deviceWentOfflineModal.text') }}</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" @click="closeExpertMode()">{{
              $t('device.deviceWentOfflineModal.buttonText')
            }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <DeviceEditModal
        ref="deviceEditModal"
        v-if="
          permissionChecker(permissions.CONFIGURATION_CHANGES, eligibilities.DEVICE_CONFIGURATION)
        "
        :deviceId="device.id"
        :show="editDeviceModal"
        :permissionChecker="permissionChecker"
        @close="editDeviceModal = false"
        @save="$emit('refetchDevice')"
      ></DeviceEditModal>
      <ChangeServiceCompanyModal
        v-if="!currentCompanyId && isDeviceAdmin"
        v-model="changeServiceCompanyModalOpen"
        :deviceId="device.id"
      />
      <v-dialog
        v-model="sequenceWizardOpen"
        @click:outside="
          serviceSequenceWizardIsClosable ? (tryCloseServiceSequenceWizard = true) : undefined
        "
        persistent
        hide-overlay
        :width="serviceSequenceWizardWidth"
        content-class="service-sequence-dialog"
      >
        <v-btn
          v-if="serviceSequenceWizardIsClosable"
          @click="tryCloseServiceSequenceWizard = true"
          class="close-btn"
          color="white"
          icon
          ><v-icon>mdi-close</v-icon></v-btn
        >
        <ServiceSequenceWizard
          v-if="sequenceWizardOpen"
          :deviceId="device.id"
          :serviceSequenceName="serviceSequenceName"
          :soloPage="false"
          :closeFromOutside.sync="tryCloseServiceSequenceWizard"
          :width.sync="serviceSequenceWizardWidth"
          @disableClose="serviceSequenceWizardIsClosable = $event"
          @close="closeServiceSequenceWizard()"
        />
      </v-dialog>
      <v-dialog v-model="changeOperatingModeModalOpen" width="unset">
        <div class="change-operating-mode-modal-wrapper">
          <h2 class="mb-7">{{ $t('global.changeOperatingModeModal.heading') }}</h2>
          <div v-if="device.connectionStatus === 'OFFLINE'" class="mb-7">
            {{ $t('global.changeOperatingModeModal.deviceIsOffline') }}
          </div>
          <div v-if="isRunningServiceSequence()" class="mb-7">
            {{ $t('global.changeOperatingModeModal.serviceSequenceInProgress') }}
          </div>
          <v-form @submit.prevent="changeOperatingModeConfirmationModalOpen = true">
            <v-select
              :items="operatingModeOptions"
              :label="$t('device.deviceListHeaders.operatingMode')"
              v-model="newOperatingMode"
              filled
              :style="{ maxWidth: '350px' }"
              :disabled="device.connectionStatus === 'OFFLINE' || isRunningServiceSequence()"
            ></v-select>
            <div class="mt-8 text-right">
              <v-btn @click="changeOperatingModeModalOpen = false" depressed>{{
                $t('global.cancel')
              }}</v-btn>
              <v-btn
                type="submit"
                color="primary"
                class="ml-3"
                depressed
                :disabled="newOperatingMode === device.operatingMode || isRunningServiceSequence()"
                >{{ $t('global.save') }}</v-btn
              >
            </div>
          </v-form>
        </div>
      </v-dialog>
      <ConfirmationDialog
        v-model="changeOperatingModeConfirmationModalOpen"
        :heading="$t('global.changeOperatingModeConfirmationModal.heading')"
        :text="$t('global.changeOperatingModeConfirmationModal.text')"
        :action="updateOperatingMode"
      />
      <ConfirmationDialog
        v-model="confirmDiscardChangesModalOpen"
        :heading="$t('global.confirmDiscardChangesModal.heading')"
        :text="$t('global.confirmDiscardChangesModal.text')"
        confirmBtnColor="error"
        :confirmText="$t('global.discardChanges')"
        :action="deviceCancelConfiguration"
      />
    </template>
  </div>
</template>

<script>
import { devicePropagateSettings } from '../../graphql/mutations/deviceControl'
import { deviceOperatingModeUpdate } from '../../graphql/mutations/deviceOperatingModeUpdate'
import { deviceCancelConfiguration } from '../../graphql/mutations/deviceCancelConfiguration'
import { operatingModes } from '../../graphql/query/operatingModes'
import formatDistanceToNow from 'date-fns/formatDistanceToNow'
import { dateLocales } from '@/config/dateFnsLocales'
import { mapGetters } from 'vuex'
import { permissions } from '@/config/permissions'
import { eligibilities } from '@/config/eligibilities'
import NumberControl from '@/components/DeviceDetail/Controls/NumberControl'
import ToggleControl from '@/components/DeviceDetail/Controls/ToggleControl'
import ButtonControl from '@/components/DeviceDetail/Controls/ButtonControl'
import EnumControl from '@/components/DeviceDetail/Controls/EnumControl'
import ToggleControlTile from '@/components/DeviceDetail/Controls/ToggleControlTile'
import ServiceSequenceTile from '@/components/DeviceDetail/ServiceSequenceTile'
import ServiceSequenceWizard from '@/components/ServiceSequence/ServiceSequenceWizard'
import {
  deviceStartOverrideControl,
  deviceEndOverrideControl,
} from '@/graphql/mutations/deviceControl'
import DeviceEditModal from '@/components/DeviceDetail/DeviceEditModal'
import ComposedTab from '@/components/DeviceDetail/Tabs/ComposedTab'
import DisplayTab from '@/components/DeviceDetail/Tabs/DisplayTab'
import InstallationSiteTab from '@/components/DeviceDetail/Tabs/InstallationSiteTab'
import GraphsTab from '@/components/DeviceDetail/Tabs/GraphsTab'
import HistoryTab from '@/components/DeviceDetail/Tabs/HistoryTab'
import TimeScheduleTab from '@/components/DeviceDetail/Tabs/TimeScheduleTab'
import GalleryTab from '@/components/DeviceDetail/Tabs/GalleryTab'
import UserAccessesTab from '@/components/DeviceDetail/Tabs/UserAccessesTab'
import ServiceHistoryTab from '@/components/DeviceDetail/Tabs/ServiceHistoryTab'
import OperationCounterTab from '@/components/DeviceDetail/Tabs/OperationCounterTab'
import ServiceOrdersTab from '@/components/DeviceDetail/Tabs/ServiceOrdersTab'
import NotificationSettingsTab from '@/components/DeviceDetail/Tabs/NotificationSettingsTab'
import ChargingHistoryTab from '@/components/DeviceDetail/Tabs/ChargingHistoryTab'
import ReportBadge from '@/components/UI/ReportBadge'
import ChangeServiceCompanyModal from '@/components/DeviceDetail/ChangeServiceCompanyModal'
import ConfirmationDialog from '@/components/ConfirmationDialog'
import VueQrcode from 'vue-qrcode'
import { getDeviceName } from '@/utils/getDeviceName'
import { createPermissionChecker } from '@/utils/createPermissionChecker'
import { tabAccessibilityChecker } from '@/utils/tabAccessibilityChecker'
import { userDeviceAccessRoles } from '@/config/userDeviceAccessRoles'

export default {
  name: 'DeviceDetail',
  components: {
    NumberControl,
    ToggleControl,
    ButtonControl,
    ToggleControlTile,
    DeviceEditModal,
    ComposedTab,
    DisplayTab,
    InstallationSiteTab,
    GraphsTab,
    HistoryTab,
    TimeScheduleTab,
    GalleryTab,
    UserAccessesTab,
    ServiceHistoryTab,
    OperationCounterTab,
    ServiceOrdersTab,
    NotificationSettingsTab,
    ChargingHistoryTab,
    VueQrcode,
    ReportBadge,
    ChangeServiceCompanyModal,
    ServiceSequenceTile,
    ServiceSequenceWizard,
    ConfirmationDialog,
  },
  props: {
    device: Object,
    devicePermissions: Object,
    isLoading: Boolean,
  },
  data: function () {
    return {
      updateInterval: null,
      confirmOpenExpertModeDialog: false,
      expertModeDialog: false,
      confirmCloseExpertModeDialog: false,
      deviceWentOfflineModal: false,
      qrCodeDialog: false,
      editDeviceModal: false,
      toForceRerenderCounter: 0,
      tab: null,
      selectedTab: null,
      changeServiceCompanyModalOpen: false,
      sequenceWizardOpen: false,
      serviceSequenceName: null,
      changeOperatingModeModalOpen: false,
      changeOperatingModeConfirmationModalOpen: false,
      newOperatingMode: null,
      confirmDiscardChangesModalOpen: false,
      tryCloseServiceSequenceWizard: false,
      serviceSequenceWizardWidth: 'unset',
      serviceSequenceWizardIsClosable: true,
    }
  },
  created() {
    window.onbeforeunload = () => {
      if (this.device.isControlsOverrideEnabled) {
        this.endOverrideDeviceControl()
      }
    }
  },
  destroyed() {
    window.onbeforeunload = undefined
  },
  apollo: {
    operatingModes: {
      query: operatingModes,
      variables() {
        return {
          deviceType: this.device.type,
        }
      },
      skip() {
        return !this.device
      },
    },
  },
  methods: {
    openServiceSequenceWizard(serviceSequenceName) {
      this.serviceSequenceName = serviceSequenceName
      this.sequenceWizardOpen = true
    },
    formatDate(dateString) {
      const date = new Date(dateString)
      const day = date.getDate().toString().padStart(2, '0')
      const month = (date.getMonth() + 1).toString().padStart(2, '0')
      const year = date.getFullYear()

      return `${day}/${month}/${year}`
    },
    closeServiceSequenceWizard() {
      this.sequenceWizardOpen = false
      this.serviceSequenceName = null
    },
    openEligibilitiesView() {
      if (this.device.newProduct) {
        this.$router.push({
          name: 'ProductDetailPage_new',
          params: { productId: this.device.newProduct.id },
        })
      } else {
        this.$router.push({
          name: 'AddDeviceProductPage',
          params: {
            controllerId: this.device.id,
            deviceName: `${this.device.name}-${this.device.controller}`,
            thingType: this.device.thingType.name,
          },
        })
      }
    },
    propagateDeviceData: async function () {
      try {
        await this.$apollo.mutate({
          mutation: devicePropagateSettings,
          variables: {
            data: {
              deviceId: this.device.id,
            },
          },
          refetchQueries: ['GetDevice'],
        })
      } catch (error) {
        console.error(error)
        this.$toast.error(this.$t('device.sendSettingsFailed'))
      }
    },
    chooseControlType(type) {
      const controlTypeMap = {
        STATUS_INDICATOR_WITH_NUMBER: NumberControl,
        NUMBER: NumberControl,
        TOGGLE: ToggleControl,
        BUTTON: ButtonControl,
        SERVICE_SEQUENCE: ServiceSequenceTile,
        ENUM: EnumControl,
      }
      return controlTypeMap[type]
    },
    selectTabType(type) {
      const tabTypeMap = {
        COMPOSED: ComposedTab,
        DISPLAY: DisplayTab,
        INSTALLATION_SITE: InstallationSiteTab,
        GRAPHS: GraphsTab,
        HISTORY: HistoryTab,
        TIMEPLAN: TimeScheduleTab,
        GALLERY: GalleryTab,
        USER_ACCESSES: UserAccessesTab,
        SERVICE_HISTORY: ServiceHistoryTab,
        OPERATION_COUNTERS: OperationCounterTab,
        SERVICE_ORDERS: ServiceOrdersTab,
        NOTIFICATION_SETTINGS: NotificationSettingsTab,
        CHARGING_HISTORY: ChargingHistoryTab,
      }
      return tabTypeMap[type] || DisplayTab
    },
    onTabChange(selectedTab) {
      const indexOfSelected = this.accessibleTabs.findIndex((tab) => tab.type === selectedTab.type)
      this.tab = 'tab-' + indexOfSelected
    },
    controlChanged() {
      this.$emit('refetchDevice')
    },
    openExpertMode() {
      this.startOverrideDeviceControl()
      this.confirmOpenExpertModeDialog = false
      this.expertModeDialog = true
    },
    closeExpertMode() {
      this.confirmCloseExpertModeDialog = false
      this.expertModeDialog = false
      this.deviceWentOfflineModal = false
    },
    async selectFirstTabWhenAvailable() {
      // Timeout 100ms until the tabs are available, then select the first tab
      while (!this.selectedTab) {
        await new Promise((resolve) => setTimeout(resolve, 100))
        if (this.accessibleTabs.length > 0 && !this.selectedTab) {
          this.selectedTab = this.accessibleTabs[0]
        }
      }
    },
    isRunningServiceSequence() {
      return this.device.liveDeviceData.runningServiceSequence
    },
    async endOverrideDeviceControl() {
      try {
        await this.$apollo.mutate({
          mutation: deviceEndOverrideControl,
          variables: {
            data: {
              deviceId: this.device.id,
            },
          },
        })
        this.$emit('refetchDevice')
      } catch (error) {
        this.$toast.error(this.$t('device.expertModeModal.closeFailed'))
      }
    },
    async startOverrideDeviceControl() {
      try {
        await this.$apollo.mutate({
          mutation: deviceStartOverrideControl,
          variables: {
            data: {
              deviceId: this.device.id,
            },
          },
        })
        this.$emit('refetchDevice')
      } catch (error) {
        this.$toast.error(this.$t('device.expertModeModal.openFailed'))
        this.expertModeDialog = false
      }
    },
    async updateOperatingMode() {
      try {
        await this.$apollo.mutate({
          mutation: deviceOperatingModeUpdate,
          variables: {
            deviceId: this.device.id,
            operatingMode: this.newOperatingMode,
          },
        })
        this.$emit('refetchDevice')
        this.$toast.success(this.$t('global.updateOperatingModeAction.success'))
        this.changeOperatingModeConfirmationModalOpen = false
        this.changeOperatingModeModalOpen = false
      } catch (error) {
        this.$toast.error(this.$t('global.updateOperatingModeAction.failure'))
      }
    },
    async deviceCancelConfiguration() {
      try {
        await this.$apollo.mutate({
          mutation: deviceCancelConfiguration,
          variables: {
            deviceId: this.device.id,
          },
        })
        this.$emit('refetchDevice')
        this.$toast.success(this.$t('global.deviceCancelConfigurationAction.success'))
        this.confirmDiscardChangesModalOpen = false
        this.$refs.deviceEditModal.updateFormByDeviceId(
          this.device.id,
          this.$i18n.locale,
          this.permissionChecker(permissions.FULL_CUSTOMER_DATA, null),
          true,
        )
      } catch (error) {
        console.error(error)
        const parsedError = error.message?.replace('GraphQL error:', '').trim()
        this.$toast.error(
          parsedError === 'DEVICE_CANCEL_CONFIGURATION_CONFIGURATION_IN_PROGRESS'
            ? this.$t('global.deviceCancelConfigurationAction.inProgressError')
            : this.$t('global.deviceCancelConfigurationAction.failure'),
        )
      }
    },
    copyController() {
      navigator.clipboard.writeText(this.device.controller).then(
        () => {
          this.$toast.success(`"${this.device.controller}" ${this.$t('global.copiedToClipboard')}`)
        },
        (err) => {
          console.error(err)
          this.$toast.error(this.$t('global.copyToClipboardFailed'))
        },
      )
    },
    getSortedErrors(eventDetectionPoints) {
      return eventDetectionPoints
        .flatMap((eop) => eop.eventCodes)
        .sort((a, b) => (b.description < a.description ? 1 : -1))
    },
    getDeviceName,
  },
  watch: {
    expertModeDialog() {
      if (!this.expertModeDialog) {
        this.endOverrideDeviceControl()
      } else {
        this.toForceRerenderCounter++
      }
    },
    'device.connectionStatus'() {
      if (this.device.connectionStatus === 'OFFLINE' && this.expertModeDialog) {
        this.deviceWentOfflineModal = true
      } else if (this.device.connectionStatus === 'ONLINE' && this.deviceWentOfflineModal) {
        this.deviceWentOfflineModal = false
      }
    },
    'device.liveDeviceData': {
      handler(newVal) {
        if (
          newVal.runningServiceSequence &&
          this.serviceSequenceName !== newVal.runningServiceSequence.name
        ) {
          this.openServiceSequenceWizard(newVal.runningServiceSequence.name)
        }
      },
      deep: true,
    },
    sequenceWizardOpen() {
      if (!this.sequenceWizardOpen) {
        this.closeServiceSequenceWizard()
      }
    },
    changeOperatingModeModalOpen() {
      if (this.changeOperatingModeModalOpen) {
        this.newOperatingMode = this.device.operatingMode
      }
    },
  },
  computed: {
    ...mapGetters('user', ['currentCompanyPermissions', 'currentCompanyId']),
    ...mapGetters(['isUploadingImages']),
    operatingModeOptions() {
      return this.operatingModes?.map((operatingMode) => ({
        value: operatingMode.name,
        text: this.$t(`device.operatingMode.${operatingMode.name}`),
        disabled: operatingMode.default,
      }))
    },
    controlsInOfflineState() {
      return this.device.connectionStatus === 'OFFLINE' || !this.device.controlsEnabled
    },
    accessibleTabs() {
      return this.device.liveDeviceData.tabs.filter((tab) =>
        tabAccessibilityChecker(
          tab.type,
          this.permissionChecker,
          this.device.userAccess?.type,
          !this.currentCompanyId,
        ),
      )
    },
    permissions() {
      return permissions
    },
    eligibilities() {
      return eligibilities
    },
    showBlockButtons: function () {
      return this.$vuetify.breakpoint.name === 'xs'
    },
    connectionStatusChangedTime() {
      if (!this.device) {
        return null
      }
      return this.device.connectionStatusChangedTime
        ? formatDistanceToNow(new Date(this.device.connectionStatusChangedTime), {
            addSuffix: true,
            locale: dateLocales[this.$i18n.locale],
          })
        : '-'
    },
    permissionChecker() {
      return createPermissionChecker(
        this.devicePermissions,
        this.device.eligibilities,
        Boolean(!this.currentCompanyId),
      )
    },
    isDeviceAdmin() {
      return this.device.userAccess?.type === userDeviceAccessRoles.DEVICE_ADMIN
    },
    deviceConfigurationMessage() {
      if (this.device.isConfigurationJobInProgress) {
        return this.$t('global.configurationJobInProgress')
      }
      if (this.device.dataPropagationFailed) {
        return this.$t('global.dataPropagationFailed')
      }
      if (this.device.needPropagateDeviceData) {
        return this.$t('global.configurationChanged')
      }
      return null
    },
  },
  mounted() {
    this.selectFirstTabWhenAvailable()
  },
}
</script>

<style lang="less" scoped>
@import '~@/assets/less/variables.less';

.page-wrapper {
  flex-grow: 1;
  overflow: auto;

  h2 {
    font-size: 24px;
  }

  h3 {
    font-size: 18px;
    font-weight: 500;
  }

  .head {
    display: flex;
    justify-content: space-between;

    .head-buttons {
      button {
        display: block;
        margin-left: auto;
      }
    }
  }

  .buttons-row {
    display: flex;
    flex-direction: row;
    margin-top: 1rem;
  }

  .status-offline-badge {
    background-color: #fc5252;
    color: white;
    font-weight: 500;
    font-size: 11px;
    padding: 8px 11px;
    border-radius: 1px;
    margin-right: 10px;
    line-height: 11px;
  }

  .last-status-change {
    font-size: 12px;
    opacity: 0.5;
  }
}

.company {
  font-size: 10px;
  opacity: 0.5;
  font-weight: bold;
}

.device-info-wrapper {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
  align-items: center;
}

.device-info-wrapper > div {
  flex: 1 1 100%; /* Take full width on mobile */
  display: flex;
  justify-content: space-between;
  padding: 2px 0;
}

@media (min-width: 577px) {
  .device-info-wrapper {
    flex-wrap: nowrap;
  }
  .device-info-wrapper > div {
    flex: 0 1 auto;
  }
}

@media (max-width: 577px) {
  .flex-1 {
    flex: 1;
  }
}

.controller {
  font-size: 10px;
  transition: 0.1s;
  cursor: pointer;

  span:first-child {
    opacity: 0.5;
  }
  span:nth-child(2) {
    opacity: 0.5;
    font-weight: bold;
    transition: 0.1s;
  }

  i {
    opacity: 0;
  }

  &:hover {
    span:nth-child(2) {
      opacity: 0.75;
    }

    i {
      opacity: 0.75;
    }
  }
}
.span-bold {
  font-size: 10px;
  opacity: 0.5;
  font-weight: bold;
}
.operating-mode {
  font-size: 10px;
  opacity: 0.5;

  span:nth-child(2) {
    font-weight: bold;
  }
}

.reports {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;

  div {
    margin-right: 10px;
    margin-bottom: 5px;
  }
}

.controls-wrapper {
  position: relative;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
  grid-gap: 5px;
}

.headline {
  font-size: 18px !important;
  word-break: break-word;
}

.expert-mode-dialog--header {
  background-color: #fc7416;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 20px;

  span {
    color: white;
    font-size: 16px;
    font-weight: 500;
  }
}

.expert-mode-dialog--body {
  background-color: white;
  padding: 20px;

  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
  grid-gap: 20px;
}

.qr-modal-headline {
  line-height: unset;
  h1 {
    font-size: 18px;
    font-weight: 500;
  }
}

.change-operating-mode-modal-wrapper {
  background: white;
  padding: 20px;
}

@media (max-width: 576px) {
  .head {
    flex-direction: column;
    .head-buttons {
      margin-top: 15px;
    }
  }
}

/deep/.service-sequence-dialog {
  border-radius: 16px;
  position: relative;
  overflow: visible;
  display: flex;
  flex-direction: column;

  .close-btn {
    position: absolute;
    top: -18px;
    right: -18px;
    background: black;
  }
}

/deep/.v-window {
  overflow: unset;
}

/deep/.v-slide-group {
  background-color: tint(@color-brand-highlight, 50%) !important;
  margin-bottom: 30px;

  .v-tab {
    color: @color-brand-2 !important;
    opacity: 0.5;

    &--active {
      opacity: 1;
    }

    &:hover {
      text-decoration: none;
    }
  }

  .v-tabs-slider-wrapper {
    color: @color-brand-2 !important;
  }
}

.gap-4 {
  gap: 20px;
}
</style>
